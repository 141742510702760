<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
             <label>{{$t('profiles.permissions')}}</label>
          <v-select
            data-cy="permissions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="permissionsFilter"
            :options="permissionsOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:permissionsFilter', val)"
          />
        </b-col>
        <b-col
          cols="4"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('tenants.title', 1) }}</label>
          <v-select
            data-cy="tenants"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"

            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">
            {{ $t('alarm.alarm_type') }}
          </label>
          <v-select
            data-cy="alarm_type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="alarmTypeFilter"
            :options="alarmTypeOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:alarmTypeFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    permissionsOptions: {
      type: Array,
      default: null,
    },
    permissionsFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    alarmTypeOptions: {
      type: Array,
      default: null,
    },
    alarmTypeFilter: {
      type: Array,
      default: null,
    },
  },
};
</script>