<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? $t("group.add_group") : $t("group.edit_group") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('group.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Users -->
          <validation-provider #default="validationContext">
            <b-form-group
              class="text-capitalize"
              :label="$tc('users.title', 2)"
              label-for="users"
              :state="getValidationState(validationContext)"
            >
              <v-select
                data-cy="users"
                v-model="userArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="users"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        <!-- Permissions -->
          <validation-provider #default="validationContext" name="permission">
            <b-form-group
              class="text-capitalize"
              :label="$tc('permissions.title', 2)"
              label-for="permission"
              :state="getValidationState(validationContext)"
            >
              <v-select
                data-cy="permission"
                v-model="permissionsArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="permissionsOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="permission"
                :closeOnSelect="false"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tenants -->
          <validation-provider #default="validationContext" name="tenant">
            <b-form-group
              class="text-capitalize"
              :label="$tc('tenants.title', 2)"
              label-for="tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                data-cy="tenant"
                v-model="tenantsArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="filterTenantsArray"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        <!-- SharedTenants -->
          <validation-provider #default="validationContext" name="shared_tenant">
            <b-form-group
              class="text-capitalize"
              :label="$tc('shared_tenants.title', 2)"
              label-for="shared_tenant"
              :state="getValidationState(validationContext)"
              v-if="!isAdd"
            >
              <v-select
                data-cy="shared_tenant"
                v-model="sharedTenantsArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="filteredSharedTenantsArray"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="shared_tenant"
                disabled
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type Alarms -->
          <validation-provider #default="validationContext" name="type_alarm">
            <b-form-group
              class="text-capitalize"
              :label="$tc('types_alarms.title', 2)"
              label-for="type_alarm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                data-cy="type_alarm"
                v-model="typeAlarmArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="type_alarm"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        <!-- check_alarms -->
          <validation-provider name="check_alarms">
            <b-form-checkbox
              id="check_alarms"
              v-model="itemData.check_alarms"
              name="check_alarms"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t("group.check_alarms") }}
            </b-form-checkbox>
          </validation-provider>


          <!-- color -->
          <validation-provider
            #default="validationContext"
            name="color"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_level.color')"
              label-for="color"
            >
              <b-form-input
                id="color"
                v-model="itemData.frame_color"
                :state="getValidationState(validationContext)"
                trim
                type="color"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    permissionsOptions: {
      type: Array,
      required: true,
    },
    tenantOptions : {
      type: Array,
      required: true,
    },
    typeAlarmOptions : {
      type: Array,
      required: true,
    },
    userOptions : {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const permissionsArray = ref([]);
    const tenantsArray = ref([]);
    const sharedTenantsArray = ref([]);
    const filterTenantsArray = ref([]);
    const filteredSharedTenantsArray = ref([]);
    const typeAlarmArray = ref([]);
    const userArray = ref([]);

    const blankData = {
      name: "",
      users : null,
      permission: null,
      tenant: null,
      type_alarm: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      let params = {
        name: itemData.value.name,
        users: userArray.value,
        permission: permissionsArray.value,
        tenant: tenantsArray.value,
        type_alarm: typeAlarmArray.value,
        frame_color: itemData.value.frame_color,
        check_alarms: itemData.value.check_alarms
      };

      if (props.isAdd) {
        dispatch = store.dispatch("group/add", params);
      } else {
        const req = {
          id: itemData.value.id,
          params: params,
        };

        dispatch = store.dispatch("group/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``
            Object.entries(response.response.data.data).forEach(entry => {
              const [key, value] = entry
              string += `${key} - ${value}<br>`
            })
            toast({
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string
              },
            },{
              timeout: 10000
            }
            )
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          };
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        permissionsArray.value = props.itemEdit.permissions.map(
          (item) => item.id
        );

        sharedTenantsArray.value = props.itemEdit.sharedTenants.map(
                                item => item.id
                              );

        tenantsArray.value = props.itemEdit.tenants
                              .map(
                                item => item.id
                              );

        filterTenantsArray.value = props.tenantOptions.filter(tenant => !sharedTenantsArray.value.includes(tenant.value))

        filteredSharedTenantsArray.value = props.tenantOptions.filter(tenant => !tenantsArray.value.includes(tenant.value))

        typeAlarmArray.value = props.itemEdit.types_alarms.map(
          (item) => item.id
        );

        userArray.value = props.itemEdit.users.map(
          (item) => item.id
        );



      }else{
        tenantsArray.value = [],
        permissionsArray.value = [],
        typeAlarmArray.value = []
        userArray.value = []
        filterTenantsArray.value = props.tenantOptions
        filteredSharedTenantsArray.value = props.tenantOptions
        itemData.value.frame_color = '#FF0000'
      }
    };

    return {
      permissionsArray,
      tenantsArray,
      typeAlarmArray,
      sharedTenantsArray,
      filterTenantsArray,
      filteredSharedTenantsArray,
      userArray,
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>
