import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('permission/getAll')

  const permissionsOptions = computed(() => store.getters['permission/getSelectedItems'])
  const permissionsFilter = ref(null)

  if (router.currentRoute.params.id_permission)
  permissionsFilter.value = parseInt(router.currentRoute.id_permission)

  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  store.dispatch('type_alarm/getAll')
  const alarmTypeOptions = computed(() => store.getters['type_alarm/getSelectedItems'])
  const alarmTypeFilter = ref(null)

  store.dispatch('user/getAll')
  const userOptions = computed(() => store.getters['user/getSelectedItems'])
  //const alarmTypeFilter = ref(null)


  const tableColumns = [
    { label: 'group.name', key: 'name', sortable: true },
    { label: 'users.title', key: 'users', sortable: false,
      formatter: (value, key, item) => {
        if(value) return value.map(item => { return item.email}).join(', ')
        return ""
      }
    },
    { label: 'frame', key: 'check_alarms', sortable: true },
    { label: 'alarm_level.color', key: 'frame_color', sortable: true },
    { label: 'tenants.title', key: 'tenants', sortable: false,
      formatter: (value, key, item) => {
        if(value) {
          return value.map(item => { return item.name}).join(', ')
        }
      }
    },
    { label: 'shared_tenants.title', key: 'sharedTenants', sortable: false,
      formatter: (value, key, item) => {
        if(item.sharedTenants) {
          return value.map(item => { return item.tenant_name}).join(', ')
        }
      }
    },
    { label: 'permissions.title', key: 'permissions', sortable: false,
      formatter: (value, key, item) => {
        if(value)  return value.map(item => { return item.description}).join(', ')
        return ""
      }
    },
    { label: 'types_alarms.title', key: 'types_alarms', sortable: false,
      formatter: (value, key, item) => {
        if(value) return value.map(item => { return item.description}).join(', ')
        return ""
      }
    },
  ]

  if (ability.can('update', 'Group') == true || ability.can('delete', 'Group') == true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('group/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_permission: permissionsFilter.value,
      id_tenant: tenantFilter.value,
      id_type_alarm : alarmTypeFilter.value
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([permissionsFilter, tenantFilter, alarmTypeFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    permissionsOptions,
    permissionsFilter,
    tenantOptions,
    tenantFilter,
    alarmTypeOptions,
    alarmTypeFilter,
    userOptions,
    refetchData,
  }
}
